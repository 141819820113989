import { AppBar, Collapse, IconButton, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import "./Header.css";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link as Scroll } from "react-scroll";

const Header = () => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <Box className="HeaderRoot" id="TessaHeader">
      <AppBar elevation={0}>
        <Toolbar>
          <h1>
            My<span>Tessa</span>.
          </h1>
          <IconButton>
            <SortRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedSize={50}
      >
        <Box>
          <h1>
            Welcome to <br />
            My <span className="Tessa">Tessa</span>.
          </h1>
          <Scroll to="place-to-visit" smooth={true}>
            <IconButton>
              <KeyboardArrowDownIcon
                className="goDown"
                sx={{ fontSize: "40px", color: "#5aff3d" }}
              />
            </IconButton>
          </Scroll>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Header;
