import { Box, CssBaseline } from "@mui/material";
import React from "react";
import "./App.css";
import Header from "./components/Header";
import PlaceToVisit from "./components/PlaceToVisit";

function App() {
  return (
    <React.Fragment>
      <Box className="Root">
        {/* The CssBaseline component helps to kickstart an elegant, 
        consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Header />
        <PlaceToVisit />
      </Box>
    </React.Fragment>
  );
}
export default App;
