import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./ImageCard.css";
import Pok from "../assets/pok.jpg";
import Gomez from "../assets/gomez.jpg";
import { Collapse } from "@mui/material";

export default function ImageCard({ place, checked }) {
  return (
    <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
      <Card
        className="IC"
        sx={{ backgroundColor: "rgb(0, 0, 0, 0.8)", borderRadius: "10px" }}
      >
        <CardMedia
          component="img"
          alt=""
          image={place.imageUrl === "POK" ? Pok : Gomez}
          sx={{ border: "none" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {place.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {place.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    </Collapse>
  );
}
