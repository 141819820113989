import { Box } from "@mui/material";
import React from "react";
import UseWindowPosition from "./hook/UseWindowPosition";
import ImageCard from "./ImageCard";
import Places from "./places/Places";
import "./PlaceToVisit.css";
//import { styled } from "@mui/material/styles";

/*const Root = styled("Box")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));*/
const PlaceToVisit = () => {
  const checked = UseWindowPosition("TessaHeader");
  return (
    <React.Fragment>
      <Box className="PlaceToVisit" id="place-to-visit">
        <ImageCard place={Places[0]} checked={checked} />
        <ImageCard place={Places[1]} checked={checked} />
      </Box>
    </React.Fragment>
  );
};

export default PlaceToVisit;
